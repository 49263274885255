import React from "react";
import "./gridlines.scss";

const GridLines = () => {
  return (
    <div className="gridline-container">
      <div className="gridline"></div>
      <div className="gridline"></div>
      <div className="gridline"></div>
      <div className="gridline"></div>
    </div>
  );
};

export default GridLines;
