const musicLibraryItems = [
  {
    id: 0,
    title: "Echo Beach",
    color: "musicLibraryColor2",
    playlistURL: "1Z7Lp0lzHQtdh28yUxddD6",
  },
  {
    id: 1,
    title: "Acceptable Country",
    color: "musicLibraryColor1",
    playlistURL: "1t7UqZGVLQzmEBdQ5uOQaz",
  },
  {
    id: 2,
    title: "Unplugged Pop",
    color: "musicLibraryColor4",
    playlistURL: "7u39Z1ivajbKGkszD0V368",
  },
  {
    id: 3,
    title: "Open Adventure",
    color: "musicLibraryColor3",
    playlistURL: "0ABStwDK1NL8BmIAxgcIb2",
  },
];

export default musicLibraryItems;
