import React from "react";
import GitLabIcon from "@ant-design/icons/GitlabOutlined";
import GitHubIcon from "@ant-design/icons/GithubOutlined";
import DribbbleIcon from "@ant-design/icons/DribbbleOutlined";
import InstagramIcon from "@ant-design/icons/InstagramOutlined";
import LinkedInIcon from "@ant-design/icons/LinkedinOutlined";
import EmailIcon from "@ant-design/icons/SendOutlined";

export const socialLinks = [
  {
    link: "https://gitlab.com/seandorr",
    icon: <GitLabIcon />,
  },
  {
    link: "https://github.com/seandorr",
    icon: <GitHubIcon />,
  },
  {
    link: "https://dribbble.com/SeanDorr",
    icon: <DribbbleIcon />,
  },
  {
    link: "https://www.instagram.com/sean_dorr/",
    icon: <InstagramIcon />,
  },
  {
    link: "https://www.linkedin.com/in/sean-dorr-42ab6a84/",
    icon: <LinkedInIcon />,
  },
  {
    link: "mailto:seanmichael.dorr@gmail.com",
    icon: <EmailIcon />,
  },
];
