const translationsPath =
  "project.nominapress.facialRecogValidationSlider.imgAlts.";

export const facialRecogValidationImages = [
  {
    image: "Nominapress-Facial_Recognition-step1.jpg",
    alt: `${translationsPath}step1`,
  },
  {
    image: "Nominapress-Facial_Recognition-step2.jpg",
    alt: `${translationsPath}step2`,
  },
  {
    image: "Nominapress-Facial_Recognition-step3.jpg",
    alt: `${translationsPath}step3`,
  },
];

export const facialRecogLoginImage = [
  {
    image: "facial-recog-login.jpg",
    alt: "project.nominapress.facialRecogLoginSlider.imgAlt",
  },
];
